import React, { useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const Pagination = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation()
    const navigate = useNavigate()
    const [page, setPage] = useState(searchParams.get('page') ? parseInt(searchParams.get('page'), '10') : 1)

    const handleBackward = () => {
        if (page > 1){
            setPage(page-1)
            navigate(`${location.pathname}?page=${parseInt(page, '10')-1}`)
        }
    }

    const handleForward = () => {
        setPage(page+1)
        navigate(`${location.pathname}?page=${parseInt(page, '10')+1}`)
    }
  return (
    <form action="" method="get" className="col-12 d-flex justify-content-center align-items-center gap-2 mt-5" id='paginationForm'>
        <span className='btn btn-outline-primary' role='button' id='precedent' onClick={() => handleBackward()}>
            <i className="ti ti-arrow-left" aria-hidden="true"></i>
            Précédent
        </span>

        <input type="number" name="page" id="page" value={page} onChange={(e) => setPage(e.target.value)}  className='btn border-primary' style={{width: '75px'}} min='1'/>

        <span className=' btn btn-outline-primary' role='button' id='suivant' onClick={() => handleForward()}>
            Suivant
            <i className="ti ti-arrow-right" aria-hidden="true"></i>
        </span>
    </form>
  )
}

export default Pagination