import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import PackageContext from '../../context/package/PackageContext'

const PackageHeader = () => {
    const {idGroupage} = useParams()
    const {groupage, resetFilters, label, setLabel} = useContext(PackageContext)
  return (
    <div className='d-flex flew-column flex-md-row justify-content-between align-items-center border-bottom pb-3'>
    <h5 className="card-title fw-semibold">Liste des paquets</h5>
    {groupage.status === 'CREATED' && <a href={`/groupages/${idGroupage}/packages/new`} className='btn btn-primary'><i className="ti ti-file-plus me-2"></i> Nouveau paquet</a>}
    <div className='d-flex gap-2'>
      <form class="form-inline my-2 my-lg-0">  
        <input class="form-control mr-sm-2" type="search" placeholder="Label" aria-label="Label" value={label} onChange={(e) => setLabel(e.target.value)} />  
      </form> 
      <button className='btn btn-success' data-bs-toggle="modal" data-bs-target="#package_filter_modal">Filtres</button>
      <button className='btn btn-success' data-bs-toggle="modal" data-bs-target="#package_sort_modal">Trier</button>
      <button type="submit" class="btn btn-warning" onClick={() => resetFilters()}>Effacer</button>
    </div>
</div>
  )
}

export default PackageHeader