import React, { useContext } from 'react'
import GroupageContext from '../../context/groupage/GroupageContext'

const GroupageSearchModal = () => {
    const { labelFiltres, handleLabelCheckboxChange, statutFiltres, handleStatutCheckboxChange, durationSort, priceSort, setDurationSort, setPriceSort} = useContext(GroupageContext)
    const labelValues = ['MCO', 'MCO_WITHOUT_CUSTOM_CLEARANCE', 'AIR_CARGO_WITHOUT_CUSTOM_CLEARANCE', 'AIR_CARGO']
    const statutValues = ['CREATED', 'IN_TRANSIT', 'CUSTOM_CLEARANCE', 'ARRIVED']
    const sortValues = ['Croissant', 'Décroissant'] 

    console.log(durationSort, priceSort)

  return (
    <>
        <div class="modal fade" id="groupage_filter_modal" tabindex="-1" role="dialog" aria-labelledby="groupage_filter_modal_title" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="groupage_filter_modal_title">Filtrez les groupages</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <h6>Par label</h6>
                        {
                            labelValues.map((value) =>
                                <div className="mb-1 form-check">
                                    <input type="checkbox" class="form-check-input" checked={labelFiltres.includes(value)} onChange={() => handleLabelCheckboxChange(value)} value={value} id={value}/>
                                    <label class="form-check-label" htmlFor={value}>{value}</label>
                                </div>
                            )
                        }

                        <h6>Par statut</h6>
                        {
                            statutValues.map((value) =>
                                <div className="mb-1 form-check">
                                    <input type="checkbox" class="form-check-input" checked={statutFiltres.includes(value)} onChange={() => handleStatutCheckboxChange(value)} value={value} id={value}/>
                                    <label class="form-check-label" htmlFor={value}>{value}</label>
                                </div>
                            )
                        }
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Fermer</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="groupage_sort_modal" tabindex="-1" role="dialog" aria-labelledby="groupage_sort_modal_title" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="groupage_sort_modal_title">Triez les groupages</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <h6>Par durée</h6>
                        {
                            sortValues.map((value) =>
                                <div className="mb-1 form-check">
                                    <input type="radio" class="form-check-input" checked={durationSort === value} onChange={(e) => setDurationSort(e.target.value)} value={value} id={`${value}-duree`}/>
                                    <label class="form-check-label" htmlFor={`${value}-duree`}>{value}</label>
                                </div>
                            )
                        }

                        <h6>Par Prix</h6>
                        {
                            sortValues.map((value) =>
                                <div className="mb-1 form-check">
                                    <input type="radio" class="form-check-input" checked={priceSort === value} onChange={(e) => setPriceSort(e.target.value)} value={value} id={`${value}-prix`}/>
                                    <label class="form-check-label" htmlFor={`${value}-prix`}>{value}</label>
                                </div>
                            )
                        }
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Fermer</button>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default GroupageSearchModal