import { createContext, useContext, useEffect, useState } from "react";
import AuthenticationContext from "../authentication/AuthenticationContext";
import { useNavigate, useParams } from "react-router-dom";
import api, { apiClientGet, apiClientPut } from "../../api/api";
import { Bounce, toast } from "react-toastify";

const PackageEditContext = createContext({})

export const PackageEditProvider = ({ children }) => {
    const { authenticationToken } = useContext(AuthenticationContext)
    const { idPackage } = useParams()
    const navigate = useNavigate()

    const [id, setId] = useState(idPackage)
    const [label, setLabel] = useState('')
    const [weight, setWeight] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [senderName, setSenderName] = useState('')
    const [senderPhone, setSenderPhone] = useState('')
    const [receiverName, setReceiverName] = useState('')
    const [receiverPhone, setReceiverPhone] = useState('')
    const [status, setStatus] = useState('')
    const [paymentStatus, setPaymentStatus] = useState('')

    useEffect(() => {
        apiClientGet(`/admin/groupage/parcel?id=${idPackage}`).then((res) => {
            setId(res.data.id)
            setLabel(res.data.label)
            setWeight(res.data.weight)
            setDiscount(res.data.discount ? res.data.discount : 0)
            setSenderName(res.data.senderName)
            setSenderPhone(res.data.senderPhone)
            setReceiverName(res.data.receiverName)
            setReceiverPhone(res.data.receiverPhone)
            setPaymentStatus(res.data.paymentStatus)
            setStatus(res.data.status)
        }).catch((err) => {
            toast.error("Echec de la recupération des infos du paquet", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        })
    }, [authenticationToken, idPackage])

    const handleEditPackage = (e) => {
        e.preventDefault()

        const data = {
            id, label, weight, discount, senderName, senderPhone, receiverName, receiverPhone, paymentStatus, status
        }

        apiClientPut(`admin/groupage/parcel`, data).then((res) => {
            toast.success("Paquet modifié avec succès", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
            console.log(res.data)
            navigate(`/packages/${idPackage}/infos`)
        }).catch((err) => {
            toast.error("Echec de la modification du paquet", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        })
    }

    return (
        <PackageEditContext.Provider
            value={
                {
                    handleEditPackage,
                    id,
                    label, setLabel,
                    weight, setWeight,
                    discount, setDiscount,
                    senderName, setSenderName,
                    senderPhone, setSenderPhone,
                    receiverName, setReceiverName,
                    receiverPhone, setReceiverPhone,
                    paymentStatus, setPaymentStatus,
                    status, setStatus
                }
            }
        >
            {children}
        </PackageEditContext.Provider>
    )
}

export default PackageEditContext