import React, { useContext } from 'react'
import GroupageContext from '../../context/groupage/GroupageContext'

const GroupageTableHead = () => {
    const {labelFiltres, statutFiltres, durationSort, priceSort} = useContext(GroupageContext)
  return (
    <thead className="text-dark fs-4">
        <tr>
            <th className="border-bottom-0 text-start">
                <h6 className="fw-semibold mb-0">Label {labelFiltres.length > 0 && ` (${labelFiltres.join(',')})`}</h6>
            </th>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Destination</h6>
            </th>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Date de départ</h6>
            </th>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Durée {durationSort && ` (${durationSort})`}</h6>
            </th>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Prix {priceSort && ` (${priceSort})`}</h6>
            </th>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Statut {statutFiltres.length > 0 && ` (${statutFiltres.join(',')})`}</h6>
            </th>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Actions</h6>
            </th> 
        </tr>
    </thead>
  )
}

export default GroupageTableHead