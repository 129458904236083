import React, { useContext } from 'react'
import GroupageContext from '../../context/groupage/GroupageContext'

const GroupageHeader = () => {
  const { destination, setDestination, resetFilters } = useContext(GroupageContext)
  return (
    <div className='d-flex flew-column flex-md-row justify-content-between align-items-center border-bottom pb-3'>
        <h5 className="card-title fw-semibold">Liste des groupages</h5>
        <div className='d-flex gap-2'>
          <form class="form-inline my-2 my-lg-0">  
            <input class="form-control mr-sm-2" type="search" placeholder="Destination" aria-label="Destination" value={destination} onChange={(e) => setDestination(e.target.value)} />  
          </form> 
          <button className='btn btn-success' data-bs-toggle="modal" data-bs-target="#groupage_filter_modal">Filtres</button>
          <button className='btn btn-success' data-bs-toggle="modal" data-bs-target="#groupage_sort_modal">Trier</button>
          <button type="submit" class="btn btn-warning" onClick={() => resetFilters()}>Effacer</button>
        </div>
    </div>
  )
}

export default GroupageHeader