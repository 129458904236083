import React, { useContext } from 'react'
import PackageContext from '../../context/package/PackageContext'

const PackageTableHead = () => {
    const {weightSort, priceSort, paiementFiltre, statutFiltres} = useContext(PackageContext)
  return (
    <thead className="text-dark fs-4">
        <tr>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Label</h6>
            </th>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Poids {weightSort && ` (${weightSort})`}</h6>
            </th>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Prix {priceSort && ` (${priceSort})`}</h6>
            </th>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Paiement {paiementFiltre === '1' ? ` (Payé)` : ` (Non payé)`}</h6>
            </th>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Statut {statutFiltres.length > 0 && ` (${statutFiltres.join(',')})`}</h6>
            </th>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Date de création</h6>
            </th>
            <th className="border-bottom-0">
                <h6 className="fw-semibold mb-0">Actions</h6>
            </th> 
        </tr>
    </thead>
  )
}

export default PackageTableHead