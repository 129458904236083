import React from 'react'
import GroupageHeader from '../../components/header/GroupageHeader'
import GroupageTableHead from '../../components/table_head/GroupageTableHead'
import GroupageTableBody from '../../components/table_body/GroupageTableBody'
import Pagination from '../../components/pagination/Pagination'
import GroupageSearchModal from '../../components/modal/GroupageSearchModal'

const GroupageList = () => {
  return (
    <>
        <div className='row'>
            <div className="col-lg-12 d-flex align-items-stretch">
                <div className="card w-100">
                    <div className="card-body p-4">
                        <GroupageHeader/>
                        <div className="table-responsive">
                            <table className="table text-nowrap mb-0 align-middle text-center">
                                <GroupageTableHead />
                                <GroupageTableBody />
                            </table>
                        </div>
                        <Pagination />
                    </div>
                </div>
            </div>
        </div>
        <GroupageSearchModal />
    </>
  )
}

export default GroupageList