import { createContext, useContext, useEffect, useState } from "react";
import AuthenticationContext from "../authentication/AuthenticationContext";
import { useParams } from "react-router-dom";
import api, { apiClientGet } from "../../api/api";
import { Bounce, toast } from "react-toastify";

const ServiceInfosContext = createContext({})

export const ServiceInfosProvider = ({ children }) => {
    const { authenticationToken } = useContext(AuthenticationContext)
    const { idService } = useParams()
    const [service, setService] = useState({})

    useEffect(() => {
        apiClientGet(`admin/groupage/service?id=${idService}`).then((res) => {
            setService(res.data)
        }).catch((err) => {
            toast.error("Une erreur est survenue lors de la recupération du service. Veuillez reéssayer !!!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        })
    }, [authenticationToken, idService])
    return (
        <ServiceInfosContext.Provider
            value={
                {
                    service
                }
            }
        >
            {children}
        </ServiceInfosContext.Provider>
    )
}

export default ServiceInfosContext