import { createContext, useEffect, useState } from 'react'
import { apiClientGet } from '../../api/api'

const GroupageContext = createContext({})

export const GroupageProvider = ({ children }) => {
    const [groupages, setGroupages] = useState([])
    const [labelFiltres, setLabelFiltres] = useState([])
    const [statutFiltres, setStatutFiltres] = useState([])
    const [destination, setDestination] = useState('')
    const [durationSort, setDurationSort] = useState()
    const [priceSort, setPriceSort] = useState()

    const getData = async () => {
        apiClientGet("admin/groupage/group/list")
            .then((res) => {
                console.log("RESP:", res)
                setGroupages(res.data)
            })
            .catch((err) => console.log("error", err))

    }

    useEffect(() => {
        getData()
    }, [])

    const handleLabelCheckboxChange = (critere) => {
        setLabelFiltres((prevFiltres) =>
          prevFiltres.includes(critere)
            ? prevFiltres.filter((item) => item !== critere)
            : [...prevFiltres, critere]
        );
      };

    const handleStatutCheckboxChange = (critere) => {
        setStatutFiltres((prevFiltres) =>
          prevFiltres.includes(critere)
            ? prevFiltres.filter((item) => item !== critere)
            : [...prevFiltres, critere]
        );
      };

    const resetFilters = () => {
        setLabelFiltres([])
        setStatutFiltres([])
        setPriceSort(undefined)
        setDurationSort(undefined)
    } 

    return (
        <GroupageContext.Provider
            value={
                {
                    groupages, setGroupages,
                    labelFiltres,
                    statutFiltres,
                    destination, setDestination,
                    durationSort, setDurationSort,
                    priceSort, setPriceSort,
                    handleLabelCheckboxChange,
                    handleStatutCheckboxChange,
                    resetFilters
                }
            }
        >
            {children}
        </GroupageContext.Provider>
    )
}

export default GroupageContext