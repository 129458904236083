import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import api, { apiClientPost } from "../../api/api";
import AuthenticationContext from "./AuthenticationContext";
import { Bounce, toast } from "react-toastify";

const LoginContext = createContext({})

export const LoginProvider = ({ children }) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const { setAuthenticatedUser, setAuthenticationToken } = useContext(AuthenticationContext)
    const navigate = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault()

        const data = {
            email,
            password
        }

        apiClientPost("admin/auth/login", data).then((res) => {
            const authenticationtoken = res.data.token

            const authenticatedUser = {
                'lastName': res.data.lastName, 
                'firstName': res.data.firstName,
                'email': res.data.email
            }

            localStorage.setItem('authenticationToken', authenticationtoken)
            localStorage.setItem('authenticatedUser', JSON.stringify(authenticatedUser))
            setAuthenticationToken(authenticationtoken)
            setAuthenticatedUser(authenticatedUser)

            setEmail('')
            setPassword('')

            toast.success("Connexion réussie", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })

            navigate('/')
            window.location.reload()
        }).catch((err) => {
            toast.error("Echec de la connexion!! Veuillez reessayer !!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        })
    }

    return (
        <LoginContext.Provider
            value={
                {
                    email, setEmail,
                    password, setPassword,
                    handleSubmit,
                }
            }
        >
            {children}
        </LoginContext.Provider>
    )
}

export default LoginContext