import React from 'react'
import PackageHeader from '../../components/header/PackageHeader'
import PackageTableHead from '../../components/table_head/PackageTableHead'
import PackageTableBody from '../../components/table_body/PackageTableBody'
import Pagination from '../../components/pagination/Pagination'
import PackageSearchModal from '../../components/modal/PackageSearchModal'

const PackageList = () => {
  return (
    <div className='row'>
        <div className="col-lg-12 d-flex align-items-stretch">
            <div className="card w-100">
                <div className="card-body p-4">
                    <PackageHeader/>
                    <div className="table-responsive">
                        <table className="table text-nowrap mb-0 align-middle text-center">
                            <PackageTableHead />
                            <PackageTableBody />
                        </table>
                    </div>
                    <Pagination />
                </div>
            </div>
        </div>

        <PackageSearchModal />
    </div>
  )
}

export default PackageList