import { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AuthenticationContext from '../authentication/AuthenticationContext'
import api, { apiClientGet, apiClientPut } from '../../api/api'
import { Bounce, toast } from 'react-toastify'

const GroupageEditContext = createContext({})

export const GroupageEditProvider = ({ children }) => {
    const { idGroupage } = useParams()
    const { authenticationToken } = useContext(AuthenticationContext)
    const navigate = useNavigate()

    const [id, setId] = useState('')
    const [expectedDepartureDay, setExpectedDepartureDay] = useState('')
    const [expectedDurationInDays, setExpectedDurationInDays] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [status, setStatus] = useState('')

    useEffect(() => {
        apiClientGet(`admin/groupage/group?id=${idGroupage}`).then((res) => {
            setId(res.data.id)
            setExpectedDepartureDay(res.data.expectedDepartureDay)
            setExpectedDurationInDays(res.data.expectedDurationInDays)
            setDiscount(res.data.discount)
            setStatus(res.data.status)

        }).catch((err) => {
            toast.error("Une erreur est survenue lors de la recupération du groupage. Veuillez reéssayer !!!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
            console.log(err)
        })

    }, [idGroupage, authenticationToken])

    const handleEditGroupage = (e) => {
        e.preventDefault()

        const data = {
            id, expectedDepartureDay, expectedDurationInDays, discount, status
        }

        apiClientPut(`admin/groupage/group`, data).then((res) => {
            toast.success("Groupage modifié avec succès", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })

            navigate(`/groupages/${idGroupage}/infos`)
        }).catch((err) => {
            toast.error("Une erreur est survenue lors de la modification du groupage. Veuillez reéssayer !!!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
            console.log(err)
        })

    }

    return (
        <GroupageEditContext.Provider
            value={
                {
                    handleEditGroupage,
                    id, setId,
                    expectedDepartureDay, setExpectedDepartureDay,
                    expectedDurationInDays, setExpectedDurationInDays,
                    discount, setDiscount,
                    status, setStatus
                }
            }
        >
            {children}
        </GroupageEditContext.Provider>
    )
}

export default GroupageEditContext