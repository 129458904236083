import { createContext, useContext, useState } from "react";
import api, { apiClientPost } from "../../api/api";
import AuthenticationContext from "../authentication/AuthenticationContext";
import { Bounce, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ServiceNewContext = createContext({})

export const ServiceNewProvider = ({ children }) => {
    const [destination, setDestination] = useState('')
    const [duration, setDuration] = useState(1)
    const [unit, setUnit] = useState('')
    const [price, setPrice] = useState(1)
    const [reduction, setReduction] = useState(1)
    const [address, setAddress] = useState('')
    const [label, setLabel] = useState('')
    const [description, setDescription] = useState('')
    const [contact, setContact] = useState('')

    const { authenticationToken } = useContext(AuthenticationContext)
    const navigate = useNavigate()

    const handleNewService = (e) => {
        e.preventDefault()

        const data = {
            "destination": destination,
            "averageDurationInDays": duration,
            "unit": unit,
            "price": price,
            "reducedPrice": reduction,
            "cargoAddress": address,
            "label": label,
            "description": description,
            "contact": contact
        }

        apiClientPost("admin/groupage/service", data).then((res) => {
            toast.success("Service ajouté avec succès", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })

            navigate(`/services/${res.data.id}/infos`)
        }).catch((err) => {
            toast.error("Une erreur est survenue lors de l'ajout du service. Veuillez reéssayer !!!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
            console.log(err)
        })
    }

    return (
        <ServiceNewContext.Provider
            value={
                {
                    handleNewService,
                    destination, setDestination,
                    duration, setDuration,
                    unit, setUnit,
                    price, setPrice,
                    reduction, setReduction,
                    address, setAddress,
                    label, setLabel,
                    description, setDescription,
                    contact, setContact
                }
            }
        >
            {children}
        </ServiceNewContext.Provider>
    )
}

export default ServiceNewContext